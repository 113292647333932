/*--------------------------------

Nucleo icon font
Generated using nucleoapp.com

-------------------------------- */

$icon-font-path: '../../../icons' !default;

@font-face {
  font-family: 'Nucleo';
  src: url('#{$icon-font-path}/Nucleo.eot');
  src:
    url('#{$icon-font-path}/Nucleo.eot') format('embedded-opentype'),
    url('#{$icon-font-path}/Nucleo.woff2') format('woff2'),
    url('#{$icon-font-path}/Nucleo.woff') format('woff'),
    url('#{$icon-font-path}/Nucleo.ttf') format('truetype'),
    url('#{$icon-font-path}/Nucleo.svg') format('svg');
}

/* base class */
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Nucleo';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.icon-rotate-90 {
  transform: rotate(90deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-y {
  transform: scaleY(-1);
}

.icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
$icons: (
  '-2': 'ea01',
  '-2-2': 'ea02',
  'Untitled': 'ea03',
  'Untitled-2': 'ea04',
  'account': 'ea05',
  'arrow-down-s': 'ea06',
  'arrow-down-s-2': 'ea07',
  'arrow-left': 'ea08',
  'arrow-left-2': 'ea09',
  'arrow-right': 'ea0a',
  'arrow-right-2': 'ea0b',
  'arrow-right-2-2': 'ea0c',
  'arrow-right-3': 'ea0d',
  'arrow-right-circle': 'ea0e',
  'arrow-right-circle-2': 'ea0f',
  'arrow-up-s': 'ea10',
  'arrow-up-s-2': 'ea11',
  'bag-add': 'ea12',
  'bag-add-2': 'ea13',
  'bag-delivery': 'ea14',
  'bag-delivery-2': 'ea15',
  'bike': 'ea16',
  'bike-2': 'ea17',
  'bike-2-2': 'ea18',
  'bike-3': 'ea19',
  'bike-4': 'ea1a',
  'black-heart': 'ea1b',
  'black-heart-2': 'ea1c',
  'black-lock': 'ea1d',
  'black-lock-2': 'ea1e',
  'bullet-list': 'ea1f',
  'bullet-list-2': 'ea20',
  'c-check': 'ea21',
  'c-check-2': 'ea22',
  'c-info-3': 'ea23',
  'c-remove': 'ea24',
  'c-remove-2': 'ea25',
  'check-circle': 'ea26',
  'check-circle-2': 'ea27',
  'check-circle-2-2': 'ea28',
  'check-circle-3': 'ea29',
  'check-plain': 'ea2a',
  'check-plain-2': 'ea2b',
  'check-s': 'ea2c',
  'check-s-2': 'ea2d',
  'check-single': 'ea2e',
  'check-single-2': 'ea2f',
  'check-xs': 'ea30',
  'check-xs-2': 'ea31',
  'chef-hat': 'ea32',
  'chef-hat-2': 'ea33',
  'chef-hat-2-2': 'ea34',
  'chef-hat-3': 'ea35',
  'chevron-down': 'ea36',
  'chevron-down-2': 'ea37',
  'chevron-right-circle': 'ea38',
  'chevron-right-circle-2': 'ea39',
  'clock': 'ea3a',
  'clock-2': 'ea3b',
  'clock-2-2': 'ea3c',
  'coins': 'ea3d',
  'coins-2': 'ea3e',
  'comment': 'ea3f',
  'comment-2': 'ea40',
  'comment-2-2': 'ea41',
  'comment-3': 'ea42',
  'coupon': 'ea43',
  'coupon-2': 'ea44',
  'coupon-2-2': 'ea45',
  'coupon-3': 'ea46',
  'coupon-3-2': 'ea47',
  'coupon-4': 'ea48',
  'cross': 'ea49',
  'cross-2': 'ea4a',
  'cross-2-2': 'ea4b',
  'cross-3': 'ea4c',
  'dashboard': 'ea4d',
  'dashboard-2': 'ea4e',
  'dezoom': 'ea4f',
  'dezoom-2': 'ea50',
  'distance': 'ea51',
  'distance-2': 'ea52',
  'distance-2-2': 'ea53',
  'distance-3': 'ea54',
  'dots': 'ea55',
  'dots-2': 'ea56',
  'facebook': 'ea57',
  'facebook-2': 'ea58',
  'facebook-2-2': 'ea59',
  'facebook-3': 'ea5a',
  'filter': 'ea5b',
  'filter-2': 'ea5c',
  'food-society': 'ea5d',
  'food-society-2': 'ea5e',
  'garbage': 'ea5f',
  'garbage-2': 'ea60',
  'group-order': 'ea61',
  'group-order-2': 'ea62',
  'hamburger': 'ea63',
  'hamburger-2': 'ea64',
  'heart': 'ea65',
  'heart-2': 'ea66',
  'home': 'ea67',
  'home-2': 'ea68',
  'hotel-bell': 'ea69',
  'hotel-bell-2': 'ea6a',
  'hourglass': 'ea6b',
  'hourglass-2': 'ea6c',
  'instagram': 'ea6d',
  'instagram-2': 'ea6e',
  'kiosk-icon-card': 'ea6f',
  'kiosk-icon-coins': 'ea70',
  'kiosk-icon-coins-2': 'ea71',
  'less': 'ea72',
  'less-2': 'ea73',
  'linkedin': 'ea74',
  'linkedin-2': 'ea75',
  'list': 'ea76',
  'list-2': 'ea77',
  'lock': 'ea78',
  'lock-2': 'ea79',
  'logout': 'ea7a',
  'logout-2': 'ea7b',
  'map-pin': 'ea7c',
  'map-pin-2': 'ea7d',
  'minus': 'ea7e',
  'minus-2': 'ea7f',
  'move': 'ea80',
  'move-2': 'ea81',
  'n-check': 'ea82',
  'n-check-2': 'ea83',
  'n-check-2-2': 'ea84',
  'n-check-3': 'ea85',
  'n-check-3-2': 'ea86',
  'n-check-4': 'ea87',
  'pencil': 'ea88',
  'pencil-2': 'ea89',
  'person-circle': 'ea8a',
  'person-circle-2': 'ea8b',
  'phone': 'ea8c',
  'phone-2': 'ea8d',
  'pig': 'ea8e',
  'pig-2': 'ea8f',
  'pin-2': 'ea90',
  'pin-2-2': 'ea91',
  'pin-3': 'ea92',
  'pin-3-2': 'ea93',
  'plan': 'ea94',
  'plan-2': 'ea95',
  'plus': 'ea96',
  'plus-2': 'ea97',
  'plus-2-2': 'ea98',
  'plus-3': 'ea99',
  'position': 'ea9a',
  'position-2': 'ea9b',
  'privacy': 'ea9c',
  'privacy-2': 'ea9d',
  'qr-code': 'ea9e',
  'qr-code-2': 'ea9f',
  'question': 'eaa0',
  'question-2': 'eaa1',
  'receipt_long': 'eaa2',
  'receipt_long-2': 'eaa3',
  'redo-arrow': 'eaa4',
  'redo-arrow-2': 'eaa5',
  'repeat-circle': 'eaa6',
  'repeat-circle-2': 'eaa7',
  'satisfied': 'eaa8',
  'satisfied-2': 'eaa9',
  'search': 'eaaa',
  'search-2': 'eaab',
  'seat': 'eaac',
  'seat-2': 'eaad',
  'seat-2-2': 'eaae',
  'seat-3': 'eaaf',
  'shop': 'eab0',
  'shop-2': 'eab1',
  'shopping-bag': 'eab2',
  'shopping-bag-2': 'eab3',
  'shopping-bag-2-2': 'eab4',
  'shopping-bag-3': 'eab5',
  'smile': 'eab6',
  'smile-2': 'eab7',
  'star': 'eab8',
  'star-2': 'eab9',
  'star-empty': 'eaba',
  'star-empty-2': 'eabb',
  'sun': 'eabc',
  'sun-2': 'eabd',
  'taxi': 'eabe',
  'taxi-2': 'eabf',
  'terrace': 'eac0',
  'terrace-2': 'eac1',
  'ticket': 'eac2',
  'tiktok': 'eac3',
  'tiktok-2': 'eac4',
  'time-arrow': 'eac5',
  'time-arrow-2': 'eac6',
  'todo': 'eac7',
  'todo-2': 'eac8',
  'todo-list': 'eac9',
  'todo-list-2': 'eaca',
  'twitter': 'eacb',
  'twitter-2': 'eacc',
  'upload': 'eacd',
  'upload-2': 'eace',
  'vespa': 'eacf',
  'vespa-2': 'ead0',
  'warning-square': 'ead1',
  'warning-square-2': 'ead2',
  'whatsapp': 'ead3',
  'wink': 'ead4',
  'wink-2': 'ead5',
  'x': 'ead6',
  'x-2': 'ead7',
  'youtube': 'ead8',
  'youtube-2': 'ead9',
  'zoom': 'eada',
  'zoom-2': 'eadb',
);

@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

@each $name, $code in $icons {
  .icon-#{'' + $name}::before {
    content: unicode($code);
  }
}
